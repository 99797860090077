<template>
  <v-card
    min-height="300"
    elevation="0"
    class="d-flex align-center justify-center"
  >
    <h2>{{message}}</h2>
  </v-card>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: () => 'Tidak ditemukan',
    },
  },
};
</script>
