<template>
  <v-container fluid>
    <common-error
      v-if="isError"
      @fetchData="fetchContractDetail"
    />
    <skeleton-loader
      v-else-if="isLoading"
    />
    <page-not-found
      v-else-if="notFound"
      message="Kontrak tidak ditemukan"
    />
    <v-row v-else>
      <v-col>
        <p class="ml-2"><strong> Detail Kontrak</strong></p>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          active-class="font-weight-bold"
        >
          <v-tab class="mr-4 text-capitalize body-1">
            <span>{{ $_strings.contract.PAGE_NAME_SHIPPER}}</span>
          </v-tab>
          <v-tab class="text-capitalize body-1">
            <span>{{ $_strings.contract.label.ATTACHMENT }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="transparent"
        >
          <v-tab-item>
            <v-card
              outlined
              class="transparent"
            >
              <section class="white pa-5 elevation-1">
                <FormInput
                  :form="form"
                  :itemsCompanyList="itemsCompanyList"
                  ref="form"
                />
              </section>
              <section class="pa-3">
                <TableUpload
                  :form="form"
                  :documents="form.documents"
                  documentType="CONTRACT"
                />
              </section>
              <section class="d-flex justify-end">
                <v-btn
                  class="mt-4"
                  color="primary"
                  large
                  @click="tab = 1"
                >
                  {{ $_strings.contract.label.NEXT }}
                </v-btn>
              </section>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              outlined
              class="transparent"
            >
              <v-container fluid class="white pa-5 elevation-1">
                <TableUpload
                  class="pl-2 pr-2 pb-5 pt-0"
                  :form="form"
                  :documents="form.documents"
                  documentType="ATTACHMENT"
                  label="Unggah Lampiran"
                />
                <!-- BUTTONS ACTIONS -->
                <v-row>
                  <v-col>
                    <v-btn
                      depressed
                      outlined
                      large
                      @click="tab = 0"
                    >
                      {{ $_strings.contract.label.BACK }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FormInput from './FormInput.vue';
import TableUpload from './TableUpload.vue';
import SkeletonLoader from './SkeletonLoader.vue';
import PageNotFound from './PageNotFound.vue';

export default {
  components: {
    FormInput,
    TableUpload,
    SkeletonLoader,
    PageNotFound,
  },
  created() {
    this.fetchContractDetail();
  },
  data() {
    return {
      tab: 0,
      form: {
        contractNo: '',
        contractFormatType: null,
        companyId: '',
        mouId: null,
        mouNo: null,
        periodStart: null,
        periodEnd: null,
        documents: [],
      },
      itemsCompanyList: [],
      radioGroupCompany: null,
      isLoading: false,
      isError: false,
      notFound: false,
    };
  },
  methods: {
    async fetchContractDetail() {
      const dataUser = this.$store.state.user.myUserInfo;
      const { companyId } = dataUser;
      try {
        this.isLoading = true;
        this.isError = false;
        const res = await this.$_services.contract.fetchContractDetailById(companyId);
        this.form = {
          ...res.data,
          mouNo: res.data.mouNo,
        };
        this.itemsCompanyList.push({
          companyName: res.data.companyName,
          companyId: res.data.companyId,
        });
      } catch (err) {
        if (err.response && (err.response.status === 404)) {
          this.notFound = true;
          return;
        }
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
